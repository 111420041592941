.DesktopNavbar {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6em;

    z-index: 2;

    a {
        text-decoration: none;
        color: inherit;
    }

    > .SmallHeroBlob {
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 8.5em;
        top: 0;
        right: 0;
    }

    .NavWrapper {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;

        > .NavWrapperFlexLeft {
            display: flex;
            position: absolute;
            height: 100%;
            width: calc(40%);
            margin: 0 auto;
            left: 0;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;

            > .logo {
                display: block;
                position: relative;
                float: left;

                height: 4em;
                margin: 0 20px;
            }
            > .LogoText {
                display: block;
                position: relative;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                font-size: 1.7em;
                width: fit-content;
                height: fit-content;
                margin: 0;
                float: left;
                //color: #ecae58;
                // color: rgba(32, 163, 159, 1);
                // text-shadow: -2px 2px 2px rgba(32, 163, 159, 0.425);
                color: rgb(0, 0, 0);
                text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.425);
                //padding: 0.3em 0;
            }
            > .color {
                color: rgba(32, 163, 159, 1);
                text-shadow: -2px 2px 2px rgba(32, 163, 159, 0.425);
            }
        }
        > .NavWrapperFlexRight {
            display: flex;
            position: absolute;
            height: 100%;
            width: calc(100% - 250px);
            margin: 0 auto;
            right: 0;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;

            > .NavMenuitem {
                display: block;
                position: relative;
                height: fit-content;
                float: right;
                width: fit-content;
                margin: 0;
                font-size: 1.3em;
                padding: 30px 20px;
                font-weight: 900;
                text-align: center;

                transition: all 0.3s ease;

                &:hover {
                    //filter: invert(0.4);
                    color: rgb(32, 163, 158);
                    text-shadow: -2px 2px 2px rgba(32, 163, 159, 0.425);
                }
                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }
}

.MobileNavbar {
    display: block;
    position: absolute;
    width: 100%;
    height: 5em;

    z-index: 2;

    //border: 1px solid red;

    a {
        text-decoration: none;
        color: inherit;
    }

    > .NavWrapper {
        display: block;
        position: relative;
        width: calc(100% - 10em);

        height: 100%;
        margin: 0 auto;
        //border: 1px solid blue;

        z-index: 5;
        > .NavWrapperFlexCenter {
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            //border: 1px solid white;
            > .logo {
                display: block;
                position: relative;
                height: 4em;
                width: auto;
                margin: 0 5px;
            }
            > .LogoText {
                display: block;
                position: relative;
                font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
                font-size: 1.7em;
                width: fit-content;
                height: fit-content;
                margin: 0 5px;
                float: left;
                //color: #ecae58;
                color: rgb(0, 0, 0);
                text-shadow: -2px 2px 2px rgba(0, 0, 0, 0.425);
                // color: rgba(32, 163, 159, 1);
                // text-shadow: -2px 2px 2px rgba(32, 163, 159, 0.425);
                //padding: 0.3em 0;
            }
        }
    }
    > .NavHamburger {
        display: flex;
        position: absolute;
        height: 100%;
        width: 5em;
        //border: 1px solid white;
        top: 0;
        right: 0;
        flex-flow: column;
        justify-content: center;

        z-index: 5;

        > .HamburgerLine {
            display: block;
            position: relative;
            height: 3px;
            width: 50%;
            margin: 0.5em auto;
            border-bottom: 3px solid;
            border-color: rgb(86, 86, 86);
            border-radius: 2px;
            transition: 0.3s all ease;
        }

        // > .hamburger-top {
        //     //margin: 2em auto 0 auto;
        // }

        // > .hamburger-bottom {
        //     //margin: 1em auto 0 auto;
        // }
    }
    > .MobileNavbarMenu {
        display: block;
        position: fixed;
        height: 0px;
        width: 100vw;

        background-color: rgba($color: #000000, $alpha: 0.7);
        backdrop-filter: blur(3px);

        top: -2px;
        left: 0;

        transition: 0.3s all ease;
        overflow: hidden;

        //border: 1px solid red;

        > .NavMenuFlex {
            display: flex;
            position: relative;
            height: 100vh;
            width: 100vw;

            padding-top: 5em;

            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            // gap: 40px;

            z-index: 3;

            > .NavMenuitem {
                display: block;
                position: relative;
                width: fit-content;
                height: fit-content;
                text-align: center;
                font-size: 2em;
                font-weight: 700;
                margin: 20px;

                text-decoration: none;

                color: rgba(32, 163, 159, 1);
                text-shadow: -2px 2px 2px rgba(32, 163, 159, 0.425);

                transition: 0.3s ease all;

                //border: 1px solid red;

                &:hover {
                    color: rgba($color: #ecae58, $alpha: 1);
                    text-shadow: -2px 2px 2px rgba($color: #ecae58, $alpha: 0.6);
                }
                &:active {
                    transform: scale(0.95);
                }
            }
        }
    }
}

.HamburgerCheckbox {
    display: none;
    &:checked + .NavHamburger {
        > .HamburgerLine {
            border-color: rgb(255, 255, 255);
        }
        > .HamburgerTop {
            transform: translateY(calc(0.5em + 1px)) rotate(45deg);
        }
        > .HamburgerBottom {
            transform: translateY(calc(-0.5em - 1px)) rotate(-45deg);
        }
    }

    &:checked + .NavHamburger + .MobileNavbarMenu {
        top: 0;
        height: 100vh;
        padding-bottom: 5em;
    }
}

@media only screen and (max-width: 1300px) {
    .DesktopNavbar {
        > .NavWrapper {
            > .NavWrapperFlexRight {
                > .NavMenuitem {
                    padding: 30px 10px;
                }
            }
        }
    }
}
