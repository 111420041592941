.LearnMore {
    display: block;
    position: relative;
    height: fit-content;
    width: fit-content;
    text-decoration: none;

    transition: 0.3s all ease;
    color: rgb(32, 163, 158);

    > .arrow {
        padding-left: 3px;
        transition: 0.3s padding-left ease;
    }

    &:hover {
        color: rgb(32, 163, 158);
        > .arrow {
            padding-left: 10px;
        }
    }
}
