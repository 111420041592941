.Cards,
.ProjectCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start; /* Align items to the left */
    align-items: stretch;
    gap: 50px 40px; /* Increase horizontal and vertical gap */
    margin: 20px 0;
    width: 100%;
    height: fit-content;
}

.ChartsFlex {
    display: flex;
    position: relative;
    width: 100%;
    height: fit-content;

    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    margin: 10px 0;

    //border: 1px dashed red;

    > .ChartCard {
        display: flex;
        position: relative;
        width: fit-content;
        height: fit-content;

        flex-direction: column;
        justify-content: center;
        align-items: center;

        //border: 1px solid black;
    }
}
.PieChart {
    display: block;
    position: relative;
    height: 40vh;
    width: 40vh;
    margin: 10px auto;

    //border: 1px solid white;
}

.FlexRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // border: 1px solid red;
    gap: 40px;
    align-items: center;
}

.SectionHalf {
    display: block;
    width: calc(50% - 20px);
    height: fit-content;
}

.SectionImage {
    width: 100%;
    height: 24em;
    object-fit: cover;
    border-radius: 1em;
}

a {
    &:hover {
        text-decoration: none;
    }
}

@media only screen and (max-width: 1000px) {
    .Cards {
        justify-content: center;
    }

    .SectionHalf {
        min-width: 100%;
    }
}
