.TeamFlex {
    display: flex;
    position: relative;
    width: 100%;
    height: fit-content;

    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0 20px;
    gap: 3em;


    //border: 1px solid blue;
}
