.Card {
    display: block;
    position: relative;
    width: 22em;
    height: 32em;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 5px 2px rgba($color: #000000, $alpha: 0.3);
    overflow: hidden;
    //margin: 20px;

    transition: 0.3s all ease;

    &:hover {
        transform: translateY(-4px);
    }

    > .CardImg {
        display: block;
        position: relative;
        height: 45%;
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);

        > img {
            display: block;
            position: relative;
            width: 80%;
            object-fit: contain;
            height: 100%;
            margin: 0 auto;
        }
    }

    > .Heading {
        > a {
            color: rgb(32, 163, 158);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }

            > .CardHeading {
                padding: 0 1em;
                padding-top: 1em;
                font-size: 1.4em;
                color: rgb(32, 163, 158);
                text-shadow: -1px 1px 1px rgba(37, 139, 136, 0.562);
            }
        }
        > .CardHeading {
            padding: 0 1em;
            padding-top: 1em;
            font-size: 1.4em;
            color: rgb(32, 163, 158);
            text-shadow: -1px 1px 1px rgba(37, 139, 136, 0.562);
        }
    }

    > .CardText {
        padding: 1em 1.4em;
        font-size: 1em;
        font-weight: normal;
    }
}

@media only screen and (max-width: 1000px) {
    .Card {
        font-size: 14px;
        > .CardHeading {
            margin: 6px 20px;
        }
        > .CardText {
            margin: 0 20px;
        }
    }
}