.Section {
    display: block;
    position: relative;
    width: calc(100%);
    height: fit-content;
    padding: 2.4em;

    > .SectionWrapper {
        display: block;
        position: relative;
        width: 100%;
        height: fit-content;
        max-width: 1400px;
        margin: 0 auto;
    }
}

.FullSection {
    > .SectionWrapper {
        max-width: 1600px;
    }
}

@media only screen and (max-width: 1000px) {
    .section {
        width: calc(100% - 60px);
        margin: 50px 30px;
    }
}
