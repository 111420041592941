.MinorText {
    // color: #ecae58;
    // text-shadow: -1px 1px 1px rgba($color: #ecae58, $alpha: 0.5);
    margin: 0;
    font-size: 1.8em;
}

.MinorA {
    color: #74b1aa;
    text-shadow: -1px 1px 1px rgba($color: #67a09a, $alpha: 0.5);
}

.MinorB {
    color: #ecae58;
    text-shadow: -1px 1px 1px rgba($color: #ecae58, $alpha: 0.5);
}

@media only screen and (max-width: 1000px) {
    .MinorText {
        margin: 0;
    }
}
