//scale of how much images are zoomed in (with 1 being normal)
$imgZoom: 1.4;

.TeamCard {
    display: block;
    position: relative;
    max-width: 300px;
    width: 100%;
    height: fit-content;

    border-radius: 5px;
    //background-color: #fff;
    padding: 1em;
    padding-top: 5em;
    margin-top: 5em;
    background: #eee;
    box-shadow: 3px 3px 5px 2px rgba($color: #000000, $alpha: 0.3);
    .Image {
        display: block;
        height: calc(10em);
        width: calc(10em);
        position: absolute;
        border-radius: 50%;
        overflow: hidden;

        border: 10px solid #eee;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc(-50% - 0.5em));

        > img {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    > .WideImage > img {
        height: 100% !important;
        width: auto !important;
    }
    > .TallImage > img {
        width: 100% !important;
        height: auto !important;
    }
    > .Top > img {
        transform: translate(-50%, -40%);
    }
    > .ZoomImage > img {
        transform: translate(-50%, -50%) scale($imgZoom);
    }
    > .ZoomImageTOP > img {
        transform: translate(-50%, -30%) scale($imgZoom);
    }
    > .ZoomImageBOTTOM > img {
        transform: translate(-50%, -95%) scale($imgZoom);
    }

    .Major {
        font-size: 1.5em;
    }
    .Title {
        font-size: 1em;
        font-weight: 500;
        color: #444;
    }
    .Content {
        font-size: 1em;
    }
}
