.InlineRadioInput {
    display: inline-block;
    height: fit-content;
    width: fit-content;
}

.Input:checked ~ .Label {
    filter: none;
    transition: 0.3s all ease;
}

.Label {
    display: block;
    transition: 0.3s all ease;
    border: 1px solid #111;
    border-radius: 10px;
    padding: 0.5em 0.75em;
    font-weight: 400;
    filter: invert(0.3);
    cursor: pointer;
    color: #000;
    &:hover {
        transform: translateY(-3px);
    }
}