.ProjectsPage {
    display: flex;
    position: relative;
    height: fit-content;
    width: 100%;

    flex-direction: column;

    margin-top: 8em;
}

.ProjectFormFlex {
    display: flex;
    position: relative;
    width: 50%;
    height: fit-content;

    flex-direction: column;
    padding: 1em 0;
    gap: 1em;

    > p {
        position: relative;
        z-index: 1;
        font-size: 1.2em;
        line-height: 1em;
        transition: 0.3s all ease;
        text-transform: capitalize;
        font-weight: 500;
    
        color: #fff;
    }

    > label {
        margin-right: 1em;
        text-align: left;
    }
    
    > .Error {
        color: red;
    }

    > .Success {
        color: green;
    }

    > .TextBox {
        border-radius: 10px;
        width: 100%;
        height: 300px;
        padding: 1em;
        font-size: 1.4em;
    }
    
    > .Radio {
        display: flex;
        position: relative;
        height: fit-content;
        width: 100%;
    
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1em;
    
        margin-bottom: 2em;

        > p {
            position: relative;
            font-size: 1.2em;
            line-height: 1em;
            transition: 0.3s all ease;
            text-transform: capitalize;
            z-index: 1;
            font-weight: 500;
        
            color: #fff;
        }

        > input {
            height: 40px;
            padding: 0 20px;
        }
    }
}

.OtherInactive {
    opacity: 0.5;
}