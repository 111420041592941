.MiniText {
    color: #fff;
    font-size: 1.3em;
    font-weight: normal;
    line-height: 1.3em;
    margin: 0;
}

@media only screen and (max-width: 1000px) {
    .MiniText {
        margin: 0;
    }
}
