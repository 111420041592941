.EventCard {
    display: block;
    position: relative;
    width: 22em;
    height: 25em;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 5px 5px 5px 2px rgba($color: #000000, $alpha: 0.3);
    overflow: hidden;
    //margin: 20px;

    transition: 0.3s all ease;

    &:hover {
        transform: translateY(-4px);
    }

    > .CardImg {
        display: flex;
        position: relative;
        height: 45%;
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.3);

        justify-content: center;
        align-items: center;


        > img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            object-fit: cover;
            //margin: 0 auto;
            //border: 1px solid red;
        }
    }
    > .CardWrapper {
        display: flex;
        position: relative;
        width: 100%;
        height: 55%;
        padding: 14px 40px;
        //padding-bottom: 12px;

        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;

        > .CardHeading {
            font-size: 1.4em;
            line-height: 1em;
            color: rgb(32, 163, 158);
            text-shadow: -1px 1px 1px rgba(37, 139, 136, 0.562);
        }
        > .CardDatetime {
            color: rgb(118, 118, 118);
            font-size: 0.85em;
            font-weight: 400;
            line-height: 6px;
            text-transform: capitalize;
        }
        > .CardText {
            font-size: 1em;
            font-weight: normal;
            height: fit-content;
            flex-grow: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        > .CardButton {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
            padding: 6px 0;

            text-align: center;
            font-size: 1.2em;
            font-weight: 500;
            color: #fff;
            background-color: rgb(32, 163, 158);
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-decoration: none;

            transition: 0.3s all ease;

            &:hover {
                filter: brightness(0.8);
            }
            &:active {
                transform: scale(0.95);
            }
        }
    }
}

@media only screen and (max-width: 1000px) {
    .EventCard {
        font-size: 14px;
        > .CardWrapper {
            padding: 12px 20px;
        }
        // > .card-heading {
        //     margin: 6px 20px;
        // }
        // > .card-text {
        //     margin: 0 20px;
        // }
    }
}
