input, textarea {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    border-radius: 10px;
}

.InputField {
    display: inline-block;
    height: fit-content;
    min-width: 300px;
    position: relative;
    margin-top: 1.2em;
    margin-bottom: 0.6em;
    overflow: visible;
    z-index: 3;

    > input {
        position: relative;
        height: 40px;
        width: 100%;
        padding: 4px 20px;
        border: 2px solid #fff;
        outline-offset: 2px;
        font-size: 1em;
        color: var(--foreground);
        font-weight: 500;
        &:focus ~ label {
            top: -4em;
        }
    }

    > .InputLabel {
        position: relative;
        top: -3.6em;
        z-index: 1;
        font-size: 1.2em;
        line-height: 1em;
        transition: 0.3s all ease;
        text-transform: capitalize;
        z-index: 1;
        user-select: none;
        pointer-events: none;
        font-weight: 500;

        color: #fff;
    }

    > .TextAreaLabel {
        position: relative;
        top: -12em;
        z-index: 1;
        font-size: 1.2em;
        line-height: 1em;
        transition: 0.3s all ease;
        text-transform: capitalize;
        z-index: 1;
        user-select: none;
        pointer-events: none;
        font-weight: 500;

        color: #fff;
    }

    > span {
        display: none;
        position: relative;
        font-size: inherit;
        color: green;
        margin-left: 6px;
    }

    > i {
        display: none;
        position: relative;
        font-size: inherit;
        color: orange;
        margin-left: 2px;
    }
}

.validIndicator {
    &:valid ~ label > span {
        display: inline-block;
    }
}

.Required {
    display: inline-block !important;
}

._hasHelper {
    &:focus ~ div {
        visibility: visible;
    }
}

.TextBox {
    width: 100%;
    height: 200px;
    padding: 1em;

    &:focus ~ label {
        top: -12.4em;
    }
}

.InputFieldSubmit {
    height: 60px;
    margin: 1em 0;
    > input {
        background: none;
        color: #fff;
        border: 2px solid #fff;
        font-size: 1.2em;
        float: right;
        position: relative;
    
        height: 100%;
        width: 100%;
        padding: 10px 20px;
    
        border-radius: 10px;
        transition: 0.3s all ease;
        cursor: pointer;

        &:hover {
            background: #fff;
            color: #000;
        }
        &:active {
            transform: scale(0.95);
        }
    }
}