$hero-foreground-color: rgb(229, 230, 219);

.hero {
    display: block;
    position: relative;
    width: 100%;
    //height: 100vh;
    height: 102vh;
    z-index: 1;

    animation: fade-in 1s;
    //border: 1px solid blue;

    a {
        color: inherit;
        text-decoration: none;
    }

    .HeroSep {
        display: block;
        position: relative;
        width: 8em;
        height: 2px;
        border-bottom: 2px solid $hero-foreground-color;
        margin: 30px 0 40px 0;
        transform: translateX(4px);
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -6px;
            top: -3px;

            width: 3px;
            height: 3px;
            border: 2px solid $hero-foreground-color;
            border-radius: 100%;
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            right: -6px;
            top: -3px;

            width: 3px;
            height: 3px;
            border: 2px solid $hero-foreground-color;
            border-radius: 100%;
        }
    }

    .HeroMajor {
        font-size: 4.5em;
        text-align: left;
        width: fit-content;
        //margin: 0 auto;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        text-shadow: 2px 2px $hero-foreground-color;
        -webkit-text-stroke-width: 1.5px;
        //-webkit-text-stroke-color: #ecae58;
        -webkit-text-stroke-color: $hero-foreground-color;
        color: transparent;
        letter-spacing: 0px;
        padding: 10px 0;
        text-transform: uppercase;
    }

    .HeroMinor {
        font-size: 3em;
        text-align: right;
        width: fit-content;
        //margin: 0 auto;
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        letter-spacing: 5px;
        text-shadow: -2px 2px #ecae58;
        -webkit-text-stroke-width: 1.5px;
        //-webkit-text-stroke-color: #ecae58;
        -webkit-text-stroke-color: rgb(110, 110, 110);
        color: transparent;
        letter-spacing: 0px;
        padding: 10px 0;
    }

    .LearnMore {
        display: block;
        position: relative;
        font-weight: 600;
        font-size: 1.3em;
        background-color: $hero-foreground-color;
        padding: 5px 7px;
        border-radius: 3px;
        box-shadow: -2px -2px 0px 2px rgba($color: $hero-foreground-color, $alpha:
                    0.5);
        transform: translateX(2px), translateY(2px);
        cursor: pointer;

        width: fit-content;
        height: fit-content;

        > .arrow {
            margin-left: 4px;
            transition: 0.3s all ease;
        }

        &:hover {
            > .arrow {
                margin-left: 10px;
            }
        }
    }

    .ApplyButton {
        display: block;
        position: relative;
        width: fit-content;
        height: fit-content;
        margin: 10px 0;
        padding: 10px 20px;

        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        font-size: 1em;
        text-transform: uppercase;
        font-weight: 900;
        background: none;
        border: none;
        border-radius: 4px;

        color: #323232;
        background-color: rgb(32, 163, 158);
        box-shadow: -2px 2px 2px rgb(24, 132, 128);
        cursor: pointer;
        user-select: none;

        transition: 0.3s all ease;
        &:hover {
            filter: brightness(0.8);
        }
        &:active {
            transform: scale(0.95);
        }
    }

    > .HeroBlob {
        display: block;
        position: absolute;
        z-index: -1;
        width: 80%;
        height: 100%;
        top: 0;
        right: 0;
    }
    > .HeroWrapper {
        display: block;
        position: relative;
        height: calc(100vh);
        width: 100%;
        max-width: 1600px;
        margin: 0 auto;
        padding: 0 20px;
        padding-top: 6em;

        //border: 1px solid green;
        > .HeroImgTest {
            display: block;
            position: absolute;
            width: 40%;
            height: 30vh;
            background-color: red;
            right: 0;
            top: 6em;
        }

        > .HeroLeft {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            position: relative;
            //centered height
            height: fit-content;
            width: 50%;
            float: left;
            overflow: hidden;
            padding: 0 20px;
            margin-top: 10em;

            > .AcceptingMembers {
                display: block;
                position: relative;
                height: fit-content;
                width: 100%;
                margin-bottom: 1em;

                > .New {
                    color: #ffffff;
                    padding: 0.5em 0;
                    font-size: 1.4em;
                    border-radius: 0.5em;

                    > a {
                        text-decoration: underline;
                        color:rgb(24, 132, 128);

                        &:hover {
                        }
                    }
                }
            }
        }
        > .HeroRight {
            display: block;
            position: relative;
            //centered height
            height: calc(40% - 6em);
            width: 40%;
            float: right;
            overflow: hidden;

            //border: 1px solid red;
            > .HeroLogo {
                display: block;
                position: relative;
                height: 100%;
                width: auto;

                float: right;

                //border: 1px solid blue;
            }
        }
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1000px) {
    .hero {
        font-size: 10px;
        > .HeroBlob {
            width: 100%;
        }
        > .HeroWrapper {
            filter: invert(0.8);
            > .HeroLeft {
                width: 100%;
                padding-top: 0;
            }
            > .HeroRight {
                display: none;
            }
        }
    }
}
