.Footer {
    display: flex;
    position: relative;
    height: fit-content;
    min-height: 300px;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;

    //border: 1px dashed blue;
    padding-top: 80px;

    > .FooterBlob {
        display: block;
        position: absolute;
        width: 100%;
        height: calc(100%);
        top: 0px;
        left: 0;
    }

    > .FooterWrapper {
        display: flex;
        position: relative;
        width: 100%;
        max-width: 1600px;
        height: fit-content;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;

        margin: 30px auto;
        //border: 1px dashed green;

        .Major {
            display: flex;
            position: relative;
            width: fit-content;
            padding: 20px 50px;

            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 10px;

            > h1 {
                font-weight: 700;
                font-size: 1.8em;
                text-align: center;
                height: fit-content;
            }
            > .logo {
                display: block;
                position: relative;
                height: 2.5em;
                width: auto;
            }
        }
        .FooterSection {
            display: flex;
            position: relative;
            height: fit-content;
            width: fit-content;
            max-width: 50%;
            padding: 20px 20px;
            flex-direction: column;
            align-items: flex-start;

            //border: 1px dashed red;
            > .minor {
                word-wrap: break-word;
                font-weight: 700;
                letter-spacing: -1px;
                padding-bottom: 5px;
                text-align: center;
            }
            > .mini {
                word-wrap: break-word;
                padding-left: 16px;
                font-weight: 500;
            }
            > a.mini {
                transition: 0.3s all ease;
                &:hover {
                    text-decoration: underline;
                }
                &:active {
                    transform: scale(0.95);
                }
            }

            > .mini,
            .minor {
                display: block;
            }
        }
        .link {
            color: inherit;
            text-decoration: none;
        }
    }
}

// @media only screen and (max-width: 1000px) {
//     .footer {
//         padding-top: 120px;
//     }
// }
