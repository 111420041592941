.WavyBg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: #31323b;

    > .Squiggles {
        display: none;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100vh;
        background-image: url("./background-design-1.png");
        background-repeat: repeat;
        background-size: 150px;
        filter: brightness(0.5);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
